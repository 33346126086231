import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertService} from '../_alert';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

   form = new FormGroup({
    username: new FormControl('', Validators.compose([
      Validators.required,
    ])),
    password: new FormControl('', Validators.required)
  });
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  accessData: any;

  constructor(public authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, public alertService: AlertService) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.router.navigateByUrl('/');
    }
  }

  onSubmit(): void {
    this.authService.login(this.form.value).subscribe(
      async data => {
        this.tokenStorage.saveToken(data.accessToken);
        data.roles = await this.setAccess(data.roles).then(() => {
          data.roles = this.accessData;
          this.tokenStorage.saveUser(data);

          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().roles;
          this.router.navigateByUrl('/');
          this.reloadPage();
        });

      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

  async setAccess(roles): Promise<any> {
    let i = 0;
    this.accessData = roles[0].role_id;
    return Promise.resolve(this.accessData);
  }

}
