        <!-- Begin Page Content -->
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800">Adaugare Utilizator</h1>
          <!-- DataTales Example -->
          <div class="card shadow mb-4">
            <div class="card-body">
              <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()" class="user" id='addUserId'>
                <div class="form-group">
                  Nume: <input type="text" class="form-control form-control-user" id="nume" formControlName="nume" placeholder="Nume"
                    required>
                </div>

                <div class="form-group">
                  Username: <input type="text" class="form-control form-control-user" id="username" formControlName="username"
                    placeholder="Nume Utilizator" required>
                </div>

                <div class="form-group">
                  Email: <input type="email" class="form-control form-control-user" id="email" formControlName="email"
                    placeholder="Email" required>
                </div>

                <div class="form-group">
                  Numar Insigna: <input type="text" class="form-control form-control-user" id="numar_insigna" formControlName="numar_insigna"
                    placeholder="Numar insigna">
                </div>


                <div class="form-group row">
                  <div class="col-sm-3 mb-3 mb-sm-0">
                    <div class="form-group">
                      <label for="inputEmail4">Rol</label>
                      <select formControlName="role" class="form-control" #t (change)="setRole(t.value)">
                        <option value="1" *ngIf="authService.checkAccess(1)">
                          Super Administrator
                        </option>
                        <option value="2">
                          Sef Structura
                        </option>
                        <option value="3">
                          Agent Rutier
                        </option>
                        <option value="4">
                          Supraveghetor
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <div id="error"></div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 mb-3 mb-sm-0">
                    <div class="form-group" *ngIf="authService.checkAccess(1) && !isSupraveghetor">
                      <label for="inputEmail4">Structura</label>
                      <select formControlName="departament" class="form-control">
                        <option *ngFor='let loc of locatii' [value]="loc">
                          {{loc}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group" *ngIf="authService.checkAccess(2)  && !isSupraveghetor">
                      <label for="inputEmail4">Structura</label>
                      <select formControlName="departament" class="form-control">
                        <option [value]="userData.structura">
                          {{userData.structura}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <input type="password" class="form-control form-control-user" id="password" formControlName="password"
                      placeholder="Parola">
                  </div>
                  <div class="col-sm-6">
                    <input type="password" class="form-control form-control-user" id="password2" formControlName="password2"
                      placeholder="Confirmare Parola">
                  </div>
                </div>
                <button type="submit" class="btn btn-primary btn-sm float-right mr-1">
                  Adaugare</button>

                  <a routerLink="/user" class="btn btn-sm float-right mr-1">Inapoi</a>

              </form>
          </div>
          </div>
      </div>
