import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';

@Component({
  selector: 'app-status-raport',
  templateUrl: './status-raport.component.html',
  styleUrls: ['./status-raport.component.css']
})
export class StatusRaportComponent implements OnInit {
  selectedDate: string;
  lis = [];
  originalList = [];
  reportDetails = {'start': '', 'end': '', 'users': [], 'schimb': 0, 'masini': [], 'stergere': 0};
  reportGenerated = false;
  locatii = ['Toate Structurile', 'Serviciul Rutier Bihor', 'Biroul Drumuri Nationale si Europene', 'Biroul Rutier Oradea', 'Biroul Rutier Beius', 'Biroul Rutier Marghita', 'Biroul Rutier Salonta', 'Compatimentul Rutier Alesd', 'Politie Statiune Baile Felix'];
  structuraSelectata = 'Toate Structurile';
  userData: any;

  constructor(
    private router: Router,
    private httpService: ApiService,
    public authService: AuthService,
    private tokenStorage: TokenStorageService
  ) { }

  async ngOnInit() {
    this.userData = this.tokenStorage.getUser();
      if(this.userData.roles == 2) {
        this.changeStructura(this.userData.structura);
      } 
    const data = await this.httpService.getUsers().toPromise();
      this.originalList = data;
      
  }

  generateStatusReport() {
    this.reportGenerated = false;
    this.lis = this.originalList;
    this.lis.map(o => o.raport = 0);
    this.httpService.getRaport(this.reportDetails).toPromise().then(async (response: any) => {
      for (const [i, v] of response.entries()) {
        let agentIndex = this.lis.findIndex(x => x.id == v.agent);
        this.lis[agentIndex].raport = 1;
      }
      if(this.structuraSelectata != 'Toate Structurile') {
        this.lis = this.lis.filter(lista => lista.raport == 1 && lista.departament == this.structuraSelectata);
      } else {
        this.lis = this.lis.filter(lista => lista.raport == 1);
      }
      
      this.lis.sort((a, b) => (a.nume > b.nume) ? 1 : ((b.nume > a.nume) ? -1 : 0));
      this.reportGenerated = true;
    }).catch(
      err => {
        console.log(err);

      });
  }

  changeDate(element) {
    this.selectedDate = this.formatDate(element.target.value);
    this.reportDetails.start = this.selectedDate;
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  changeStructura(element) {
    this.structuraSelectata = element;
  }
}
