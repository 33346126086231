<!-- Outer Row -->
<div class="row justify-content-center">

  <div class="col-xl-5 col-lg-6 col-md-5">

    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-10">
            <div class="p-5">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4">Bine ai revenit!</h1>
              </div>
              <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="form-group">
                  <label for="password">Nume Utilizator</label>
                  <input type="text" class="form-control" name="username" formControlName="username"  required />

                </div>
                <div class="alert alert-danger" role="alert" *ngIf="form.submitted && email.invalid">
                  Username is required!
                </div>

                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" name="password" formControlName="password" required minlength="6"/>
                  <div class="alert alert-danger" role="alert" *ngIf="form.submitted && password.invalid">
                    <div *ngIf="password.errors.required">Password is required</div>
                  </div>
                </div>
                <button type="submmited" class="btn btn-primary btn-block">
                  Login
                </button>
                <div class="form-group">
                  <div class="alert alert-danger" role="alert" *ngIf="isLoginFailed">
                    Login failed: {{ errorMessage }}
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>

  </div>
