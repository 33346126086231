<!-- Begin Page Content -->
<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800">Raport Activitate</h1>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-body">
      <label>Alegeti ziua</label>
      <div class="form-group col-sm-3 mb-3 mb-sm-2">
      </div>
      <button type="button" (click)="picker1.open()" class="btn btn-primary btn-sm">
        Alege Data Inceput</button>
      <mat-form-field class="example-full-width" appearance="fill" >
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker1" name="data" (dateChange)="onChange($event.target.value, 1)"
          [min]="minDate">
        <mat-datepicker #picker1></mat-datepicker>

      </mat-form-field>

      <button type="button" (click)="picker2.open()" class="btn btn-primary btn-sm">
        Alege Data Sfarsit</button>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker2" name="data" (dateChange)="onChange($event.target.value, 2)" [min]="minDate">
        <mat-datepicker #picker2></mat-datepicker>




      </mat-form-field>
      <div class="row">

          <div class="col-sm-3 mb-3 mb-sm-0" *ngIf="authService.checkAccess(1)  || authService.checkAccess(4)">
            <div class="form-group">
              <label for="inputEmail4">Structura</label>
              <select class="form-control" (change)="changeStructura($event.target.value)">
                <option *ngFor='let loc of locatii' [value]="loc">
                  {{loc}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3 mb-3 mb-sm-0" *ngIf="authService.checkAccess(2)  || authService.checkAccess(4)">
            <div class="form-group" >
              <label for="inputEmail4">Structura</label>
              <select  class="form-control">
                <option [value]="currentUserLogged.structura">
                  {{currentUserLogged.structura}}
                </option>
              </select>
            </div>
          </div>

        <div class="col-sm-3 mb-3 mb-sm-0">
          <div *ngIf="hasMultiSelectReport">
            <label for="inputEmail4">Agenti</label>
            <select class="form-control" multiple [(ngModel)]="userList">
              <option *ngFor='let agenti of lis' [value]="agenti.id">
                {{agenti.nume}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-3 mb-3 mb-sm-0">
          <div *ngIf="hasMultiSelectReport">
            <label for="inputEmail4">Aparate Radar</label>
            <select class="form-control" multiple [(ngModel)]="masiniList">
              <option *ngFor='let masina of masini' [value]="masina.id">
                {{masina.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-sm-3 mb-3 mb-sm-0">
            <label for="inputEmail4">Schimb</label>
            <select class="form-control" (change)="changeSchimb($event.target.value)">
              <option value="0">
                Toate
              </option>
              <option value="1">
                1
              </option>
              <option value="2">
                2
              </option>
              <option value="3">
                3
              </option>
            </select>
        </div>
      </div>

      <button type="submit" (click)="generateReport()" class="btn btn-success btn-sm float-right mr-1"
        *ngIf="dateSelected">
        Generare Raport</button>
        <button type="submit" (click)="deleteReport()" class="btn btn-danger btn-sm float-right mr-1" *ngIf="removeButton">
          STERGERE RAPORT</button>
        <button class="btn btn-warning btn-sm float-right mr-1" (click)="downloadAsPDF()" *ngIf="reportGenerated">Exporta in
          PDF</button>
        <button class="btn btn-warning btn-sm float-right mr-1" (click)="downloadAsPDFFaraMentiuni()" *ngIf="reportGenerated">Exporta in
          PDF(fara mentiuni)</button>
    </div>

  </div>

  <div class="card shadow mb-4" *ngIf="reportGenerated" >

  <div class="card-body" id="pdfTable" >
    <h5 class="h6 mb-2 text-gray-800">Raport Activitate</h5>
    <h5 class="h6 mb-2 text-gray-800">Perioda:{{perioadaActivitate}} </h5>
    <h5 class="h6 mb-2 text-gray-800">Agenti:{{agentiSelectati}} </h5>
    <h5 class="h6 mb-2 text-gray-800">Schimb:{{schimbSelectate}} </h5>
    <h5 class="h6 mb-2 text-gray-800">Aparat Radar:{{masiniSelectate}} </h5>
    <div class="table-responsive" >
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Denumire</th>
            <th>Sanctiuni Total</th>
            <th>Valoare Total</th>
            <th>Total Avertismente</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let e of showReport;">
            <td  *ngIf="e.denumire" style="padding-left: {{e.level}}0px;  border-bottom: 1px solid; {{ printing ? 'font-size: 30px;' : ''}}">{{ e.denumire }}</td>
            <td class="firstRow " *ngIf="e.name" style="padding-left: {{e.level}}0px;  border-bottom: 1px solid; {{ printing ? 'font-size: 30px;' : ''}}">{{ e.name }}</td>
            <td class="firstRow "  style="border-bottom: 1px solid; {{ printing ? 'font-size: 30px;' : ''}}">{{ e.total_sanctiuni ? e.total_sanctiuni : '-'}}</td>
            <td class="firstRow "  style="border-bottom: 1px solid; {{ printing ? 'font-size: 10px;' : ''}}">{{ e.total_valoare ? e.total_valoare : '-'}}</td>
            <td class="firstRow "  style="border-bottom: 1px solid; {{ printing ? 'font-size: 10px;' : ''}}">{{ e.total_avertismente ? e.total_avertismente : '-'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="mentiuniRaport">
      Mentiuni:
      <div *ngFor="let e of mentiuni;">
        <div class="">Agent: {{e.agent.nume}}, Data: {{e.data}}</div>
        <div class="">{{e.mentiuni}}</div>
      </div>
    </div>
  </div>
  <div>
    <button type="submit" (click)="generateReport()" class="btn btn-success btn-sm float-right mr-1" *ngIf="dateSelected">
      Generare Raport</button>
      <button type="submit" (click)="deleteReport()" class="btn btn-danger btn-sm float-right mr-1" *ngIf="removeButton">
       STERGERE RAPORT</button>
    <button class="btn btn-warning btn-sm float-right mr-1" (click)="downloadAsPDF()" *ngIf="reportGenerated">Exporta in
      PDF</button>
    <button class="btn btn-warning btn-sm float-right mr-1" (click)="downloadAsPDFFaraMentiuni()"
      *ngIf="reportGenerated">Exporta in
      PDF(fara mentiuni)</button>
    </div>
  </div>


</div>
