<!-- Begin Page Content -->
<div class="container-fluid">

  <!-- Page Heading -->
  <h1 class="h3 mb-2 text-gray-800">Utilizatori</h1>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <button type="button" class="btn btn-primary pull-right" (click)="goToAddUser();">Adaugare</button>
    </div>
    <div class="card-header py-3">
      Cautare: <input type="text" (input)="updateList($event.target.value)">
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
              <tr>
                <th>Nume</th>
                <th>Nume Utilizator</th>
                <th>Email</th>
                <th>Departament</th>
                <th>Numar Insigna</th>
                <th>Actiuni</th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let e of lis;let i = index" [attr.data-index]="i">
              <td>{{ e.nume }}</td>
              <td>{{ e.username }}</td>
              <td>{{ e.email }}</td>
              <td>{{ e.departament }}</td>
              <td>{{ e.numar_insigna }}</td>
              <td><a routerLink='/edit-user/{{ e.id }}' class="btn btn-sm btn-primary mr-1 mt-1">Editare</a>
                <button id="{{ e.id }}" type="button" class="btn btn-sm btn-danger mt-1 deleteButton"
                  (click)="openConfirmationDialog(e.id, i)">Stergere</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>
