import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ApiService} from '../services/api.service';
import {TokenStorageService} from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService: ApiService, private tokenStorage: TokenStorageService) { }

  login(credentials): Observable<any> {
    return this.httpService.login({
      username: credentials.username,
      password: credentials.password
    });
  }

  checkAccess(roleid) {
    const accessRight = this.tokenStorage.getUser().roles;
    if (roleid == accessRight) return true;
    return false;
  }
}
