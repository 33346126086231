import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {AuthService} from '../_services/auth.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {Location} from '@angular/common';
import {TokenStorageService} from '../_services/token-storage.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {

  li:any;
  lis = [];
  originalList = [];
  element: any;
  renderPage = true;

  constructor(private router: Router, private httpService: ApiService, public authService: AuthService, private confirmationDialogService: ConfirmationDialogService, private _location: Location, private tokenStorageService: TokenStorageService){ }

  async ngOnInit(): Promise<void> {
    if (this.authService.checkAccess(3)) {
      this._location.back();
    }
    const structura = this.tokenStorageService.getUser().structura;
    window.scroll(0, 0);
    const users = await this.httpService.getUsers().toPromise();
    this.lis = users;
      if (!this.authService.checkAccess(1)) {
        this.lis = this.lis.filter(function(str) {return str.departament.includes(structura);});
      }
      this.lis.sort((a, b) => (a.nume > b.nume) ? 1 : ((b.nume > a.nume) ? -1 : 0));
      this.originalList = this.lis;
  }

  goToAddUser() {
    this.router.navigateByUrl('/add-user');
  }

  goToEditUser(id) {
    this.router.navigateByUrl('/edit-user/' + id);
  }

  rerender(): void {
  }

  public openConfirmationDialog(userId, index) {
    this.confirmationDialogService.confirm('Te rog confirma!', 'Esti sigur ca vrei sa stergi utilizatorul?')
      .then((confirmed) => {
        if (confirmed) {
          this.httpService.deleteUser(userId).subscribe((response => { }))
          this.lis.splice(index, 1);
        }
        this.rerender();
      })
    .catch(() => console.log(''));
  }

  public updateList(search) {
    let updatedList = this.originalList;
    updatedList = updatedList.filter(function(str) {
      return str.nume.includes(search) || str.username.includes(search) || str.departament.includes(search) || str.numar_insigna.includes(search) || str.email.includes(search);
    });
    this.lis = updatedList;
  }
}
