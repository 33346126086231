<!-- Begin Page Content -->
<div class="container-fluid">

  <!-- Page Heading -->
  <h1 class="h3 mb-2 text-gray-800">Adaugare raport</h1>

  <div class="card shadow mb-4" *ngIf="step == 1">
    <div class="card-header py-3">
      <button type="button" class="btn btn-primary pull-right" (click)="setStep(2);" *ngIf="stepOneData">Continuare</button>
    </div>
    <div class="card-body">
      <div id="notAdmin"  *ngIf="userRole > 1">
        <div>Alege Data Raport Nou</div>
        <div class="form-group">
          <div *ngIf="yesterday">
            <input id="yesterday" type="radio" value="{{yesterday}}" name="date" (change)="changeDate(yesterday)">
            <label for="male">{{yesterday}}</label>
          </div>

          <div *ngIf="today">
            <input id="today" type="radio" value="{{today}}" name="date"
              (change)="changeDate(today)">
            <label for="female">{{today}}</label>
          </div>
          <div *ngIf="!today && !yesterday">
            <label>Ati Adaugat Toate Rapoartele</label>
          </div>
        </div>

        <div *ngIf="haveDeletedReports">
          <div>Alege Data Refacere Raport</div>
            <div class="form-group">

              <div *ngFor="let dates of deletedDates">
                <label for="enum_answer_{{dates}}">
                  <input id="enum_answer_{{dates}}" [value]='dates' type="radio" name="deletedReport" (change)="changeDate(dates)">
                  {{dates}}
                </label>
              </div>
            </div>
      </div>


    </div>

    <div id="admin"  *ngIf="userRole == 1">

      <button type="button" (click)="picker1.open()" class="btn btn-primary btn-sm">
        Alege Data</button>
      <mat-form-field class="example-full-width" appearance="fill">
        <input matInput [matDatepicker]="picker1" [matDatepickerFilter]="myFilter" name="data"
          (dateChange)="changeDate($event)" [max]="maxDate" readonly>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-group row">
      <div class="col-sm-3 mb-3 mb-sm-0">
        <div class="form-group">
          <label for="inputEmail4">Alege Agent:</label>
          <select class="form-control" (change)="changeAgent($event.target.value)">
            <option value="false">
              Alegeti un agent
            </option>
            <option *ngFor="let agent of agentList" value="{{agent.id}}">
              {{agent.nume}}
            </option>
          </select>
        </div>
      </div>
    </div>

      <div class="form-group row">
        <div class="col-sm-3 mb-3 mb-sm-0">
          <div class="form-group">
            <label for="inputEmail4">Schimbul:</label>
            <select class="form-control" (change)="changeSchimb($event.target.value)">
              <option value="false">
                Alegeti un schimb
              </option>
              <option value="1">
                1
              </option>
              <option value="2">
                2
              </option>
              <option value="3">
                3
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- DataTales Example -->
  <div class="card shadow mb-4" *ngIf="step == 2">

    <div class="card-header py-3">
      <button type="button" class="btn btn-primary pull-right" (click)="setStep(1);">Refacere Raport</button>
      <button type="button" class="btn btn-primary ml-1 pull-right" (click)="setStep(4);">Previzualizare Raport</button>
    </div>
    <div class="card-header py-3">
      Data: {{selectedDate}}
      Schimb: {{selectedSchimb}}
    </div>
    <div class="card-body">
      <div class="card-header py-3">
        Cautare: <input type="text" (input)="updateList($event.target.value)">
      </div>
      <div >
        <div *ngFor="let bread of breadcrumb, index as i;" style="display: inline; padding: 0px;">
          <button type="button" *ngIf="index != i" class="btn btn-info pull-right btn-sm m-1" (click)="showSubCategories(bread.id)">{{bread.name}}</button>

        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Denumire</th>
              <th >TOTAL</th>
              <th *ngIf="hasValue">VALOARE</th>
              <th *ngIf="hasValue">AVERTISMENTE</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Denumire</th>
              <th>TOTAL</th>
              <th *ngIf="hasValue">VALOARE</th>
              <th *ngIf="hasValue">AVERTISMENTE</th>
            </tr>
          </tfoot>
          <tbody>
            <tr *ngFor="let e of lis;let i = index" [attr.data-index]="i">
              <td>{{ e.name }}</td>
              <td *ngIf="e.hasChild">
                <button id="{{ e.id }}" type="button" class="btn btn-sm btn-success mr-1 deleteButton" (click)="showSubCategories(e.id)">Selecteaza</button>
              </td>

              <td *ngIf="!e.hasChild && !hasMentiuni && e.id != 322 && !hasValue">
                <input type="number" class="form-control form-control-user" id="total_sanctiuni"
                  (change)="changeSanctiuni($event.target.value, e.id, 0)" [value]="e.total_sanctiuni ? e.total_sanctiuni : 0"
                  placeholder="{{inputName}}">
              </td>
              <!-- has value sowe need all 3 cols -->
              <td *ngIf="!e.hasChild && hasValue">
                <input type="number" class="form-control form-control-user" id="total_sanctiuni"
                  (change)="changeSanctiuni($event.target.value, e.id, 1)" [value]="e.total_sanctiuni ? e.total_sanctiuni : 0"
                  placeholder="{{inputName}}">
              </td>
              <td *ngIf="!e.hasChild && hasValue">
                <input type="number" class="form-control form-control-user" id="total_valoare"
                  (change)="changeValoare($event.target.value, e.id, 1)" [value]="e.total_valoare ? e.total_valoare : 0"
                  placeholder="Total Valoare">
              </td>
              <td *ngIf="!e.hasChild && hasValue">
                <select class="form-control" (change)="changeAvertismente($event.target.value, e.id, 1)">
                  <option [value]="0" [selected]="e.total_avertismente === '0'">
                    0
                  </option>
                  <option [value]="1" [selected]="e.total_avertismente === '1'" >
                    1
                  </option>
                  <option [value]="2" [selected]="e.total_avertismente === '2'">
                    2
                  </option>
                  <option [value]="3" [selected]="e.total_avertismente === '3'">
                    3
                  </option>
                  <option [value]="4" [selected]="e.total_avertismente === '4'">
                    4
                  </option>
                  <option [value]="5" [selected]="e.total_avertismente === '5'">
                    5
                  </option>
                  <option [value]="6" >
                    6
                  </option>
                  <option [value]="7" >
                    7
                  </option>
                  <option [value]="8" >
                    8
                  </option>
                  <option [value]="9" >
                    9
                  </option>
                  <option [value]="10" >
                    10
                  </option>
                  <option [value]="11" >
                    11
                  </option>
                  <option [value]="12" >
                    12
                  </option>
                  <option [value]="13" >
                    13
                  </option>
                  <option [value]="14" >
                    14
                  </option>

                </select>
              </td>
              <td *ngIf="!e.hasChild && e.id == 322" colspan="3">
                <textarea #mentiuni [value]="e.mentiuni"
                  (input)="changeMentiuni($event.target.value, e.id, 1)" [(ngModel)]="e.mentiuni"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>

<div class="card shadow mb-4" *ngIf="step == 4">
  <div class="card-header py-3">
    <button type="button" class="btn btn-primary pull-right"
      (click)="setStep(2);">Schimbare Raport</button>
      <button type="button" class="btn btn-warning pull-right ml-1" (click)="final();" *ngIf="!disableSave && hasAllData">Transmitere Raport</button>
  </div>
  <div class="card-body table-responsive">
    <table class="table table-hover table-bordered" id="finalInfractiuni"
      width="100%" cellspacing="0">
      <thead>
        <tr>
          <th>Denumire</th>
          <th>Sanctiuni Total</th>
          <th>Valoare Total</th>
          <th>Total Avertismente</th>
          <th>Actiuni</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let e of showReport;">
          <td  *ngIf="e.denumire" style="padding-left: {{e.level}}0px;  border-bottom: 1px solid;" [ngStyle]="e.missingValue?{'background-color': 'red'} : ''">{{ e.denumire }}</td>
          <td class="firstRow" *ngIf="e.name" style="padding-left: {{e.level}}0px;  border-bottom: 1px solid;" [ngStyle]="e.missingValue?{'background-color': 'red'} : ''">{{ e.name }}</td>
          <td class="firstRow"  style="border-bottom: 1px solid;" [ngStyle]="e.missingValue?{'background-color': 'red'} : ''">{{ e.total_sanctiuni ? e.total_sanctiuni : '-'}}</td>
          <td class="firstRow"  style="border-bottom: 1px solid;" [ngStyle]="e.missingValue?{'background-color': 'red'} : ''">{{ e.total_valoare ? e.total_valoare : '-'}}</td>
          <td class="firstRow"  style="border-bottom: 1px solid;" [ngStyle]="e.missingValue?{'background-color': 'red'} : ''">{{ e.total_avertismente ? e.total_avertismente : '-'}}</td>
          <td><button *ngIf="!e.hasChild" id="delete" type="button" class="btn btn-sm btn-danger mr-1 deleteButton" (click)="remove(e.infractiune_id)"
            >Sterge</button></td>
        </tr>
      </tbody>
      <tbody>
    </table>
    Mentiuni:
      <div *ngFor="let e of mentiuni;">
        <div>{{e.mentiuni}}</div>
      </div>
  </div>

</div>
