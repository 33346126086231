import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AddInfractiuneComponent} from './add-infractiune/add-infractiune.component';
import {AddUserComponent} from './add-user/add-user.component';
import {ContulMeuComponent} from './contul-meu/contul-meu.component';
import {EditInfractiuneComponent} from './edit-infractiune/edit-infractiune.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {HomeComponent} from './home/home.component';
import {InfractiuneComponent} from './infractiune/infractiune.component';
import {LoginComponent} from './login/login.component';
import {RaportComponent} from './raport/raport.component';
import { StatusRaportComponent } from './status-raport/status-raport.component';
import {UserComponent} from './user/user.component';
import {AnualRaportComponent} from './anual-raport/anual-raport.component';

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "home"},
  {path: "home", component: HomeComponent},
  {path: "user", component: UserComponent},
  {path: "add-user", component: AddUserComponent},
  {path: "edit-user/:id", component: EditUserComponent},
  {path: 'login', component: LoginComponent},
  {path: "contul-meu", component: ContulMeuComponent},
  {path: "infractiune", component: InfractiuneComponent},
  {path: "add-infractiune/:id", component: AddInfractiuneComponent},
  {path: "edit-infractiune/:id", component: EditInfractiuneComponent},
  {path: "raport", component: RaportComponent},
  {path: "raport-status", component: StatusRaportComponent},
  {path: "raport-anual", component: AnualRaportComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
