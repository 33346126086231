import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REPORT_KEY = 'report';
const DATE_REPORT_KEY = 'date-report';
const TURA_REPORT_KEY = 'tura-report';
const AGENT_REPORT_KEY = 'agent-report';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  public saveReport(report): void {
    window.sessionStorage.removeItem(REPORT_KEY);
    window.sessionStorage.setItem(REPORT_KEY, JSON.stringify(report));
  }

  public getReport(): any {
    return JSON.parse(sessionStorage.getItem(REPORT_KEY));
  }

  public saveData(report): void {
    window.sessionStorage.removeItem(DATE_REPORT_KEY);
    window.sessionStorage.setItem(DATE_REPORT_KEY, JSON.stringify(report));
  }

  public getData(): any {
    let tmp = sessionStorage.getItem(DATE_REPORT_KEY);
    if (tmp != 'undefined') {
      return JSON.parse(sessionStorage.getItem(DATE_REPORT_KEY));
    }
    else return null;

  }

  public saveTura(report): void {
    window.sessionStorage.removeItem(TURA_REPORT_KEY);
    window.sessionStorage.setItem(TURA_REPORT_KEY, JSON.stringify(report));
  }

  public getTura(): any {
    return JSON.parse(sessionStorage.getItem(TURA_REPORT_KEY));
  }

  public saveAgent(agentId): void {
    window.sessionStorage.removeItem(AGENT_REPORT_KEY);
    window.sessionStorage.setItem(AGENT_REPORT_KEY, JSON.stringify(agentId));
  }

  public getAgent(): any {
    return JSON.parse(sessionStorage.getItem(AGENT_REPORT_KEY));
  }
}
