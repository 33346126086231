<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="renderPage">

  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">

  </div>

  <div class="row justify-content-md-center">
    <div class="col-lg-6 ">

      <!-- Collapsable Card Example -->
      <div class="card shadow mb-4">
        <div class="card-header .text-gray-900">
          Datele mele
        </div>
        <!-- Card Header - Accordion -->
        <div class="card mb-4">
          <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Nume: </label>{{userData.nume}}
                </div>
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Username: </label>{{userData.username}}
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Departament:</label>{{userData.departament}}
                </div>
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Numar insigna:</label>{{userData.numar_insigna}}
                </div>
              </div>
              <form [formGroup]="editPasswordForm" (ngSubmit)="onSubmit()" class="user" id='addUserId'>
                <div class="form-group row">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <input type="password" class="form-control form-control-user" id="password" formControlName="password"
                      placeholder="Parola">
                  </div>
                  <div class="col-sm-6">
                    <input type="password" class="form-control form-control-user" id="password2" formControlName="password2"
                      placeholder="Confirmare Parola">
                  </div>
                </div>
                <button type="submit" class="btn btn-primary btn-sm float-right mr-1">
                  Schimba Parola</button>
              </form>
          </div>
        </div>
        <!-- Card Content - Collapse -->
      </div>

    </div>
  </div>

</div>
