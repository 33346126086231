import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../services/api.service';
import {AlertService} from '../_alert';
import {TokenStorageService} from '../_services/token-storage.service';
import {AuthService} from '../_services/auth.service';



@Component({
  selector: 'app-anual-raport',
  templateUrl: './anual-raport.component.html',
  styleUrls: ['./anual-raport.component.css']
})
export class AnualRaportComponent implements OnInit {
  maxDate = new Date(2021, 12, 30);
  minDate = new Date(2021, 1, 1);
  reportData: any;
  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };
  dateSelected = false;
  selectedDateStart = new Date(2021, 1, 1);
  selectedDateEnd = new Date(2021, 12, 31);
  selectedDate: false;
  reportGenerated = false;
  data = [];
  currentReport = [];
  originalList = [];
  showReport = [];
  mentiuni = [];
  masinaSelectata = 0;
  raportMasina = false;
  mentiuniRaport = true;

  perioadaActivitate: string;
  agentiSelectati = '';
  masiniSelectate: string;
  schimbSelectate = 'Toate schimburile';
  finalRaport: any[];

  @ViewChild('pdfTable') pdfTable: ElementRef;
  reportDetails = {'start': new Date(2021, 1, 1), 'end': new Date(2021, 12, 31), 'users': [], 'schimb': 0, 'masini': [], 'stergere': 0};
  lis = [];
  userList = [];
  hasMultiSelectReport = false;
  selectedUsers: any;
  masini: any;
  masiniList = [];
  masiniReport = [];
  totalContraventii = {denumire: '', total_sanctiuni: 0, total_avertismente: 0, total_valoare: 0};
  contraventiiIndex = -1;
  saveList = [];
  removeReport = {agent: 0, data: ''};
  removeButton = false;
  locatii = ['Toate Structurile', 'Serviciul Rutier Bihor', 'Biroul Drumuri Nationale si Europene', 'Biroul Rutier Oradea', 'Biroul Rutier Beius', 'Biroul Rutier Marghita', 'Biroul Rutier Salonta', 'Compatimentul Rutier Alesd', 'Politie Statiune Baile Felix'];
  allUsersList = [];
  currentUserLogged: any;
  printing = false;

  constructor(private httpService: ApiService, private apiService: ApiService, public alertService: AlertService, public authService: AuthService, private tokenStorageService: TokenStorageService) { }

  async ngOnInit(): Promise<void> {
    this.reportDetails.start = new Date(2021, 1, 1);
    this.reportDetails.end = new Date(2021, 12, 31);
    this.masini = await this.httpService.getInfractiuni().toPromise();
    this.masini = this.masini.filter(lista => lista.parent == 229);
    this.masini.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    if (this.authService.checkAccess(3)) {
      const currentUser = this.tokenStorageService.getUser().id;
      this.userList.push(currentUser);
      this.hasMultiSelectReport = false;
    } else {
      this.currentUserLogged = this.tokenStorageService.getUser();
      this.hasMultiSelectReport = true;
      const data = await this.httpService.getUsers().toPromise();
      this.allUsersList = data;
      this.lis = data;
      if (!this.authService.checkAccess(1)) {
        const structura = this.tokenStorageService.getUser().structura;
        this.lis = this.lis.filter(function(str) {return str.departament.includes(structura);});
      }

        this.lis.sort((a, b) => (a.nume > b.nume) ? 1 : ((b.nume > a.nume) ? -1 : 0));
    }

    this.saveList = this.originalList;

  }

  async generateReport() {
    this.showReport = [];
    this.originalList = this.saveList;
    if (!this.dateSelected) {
      this.alertService.error('va rugam selectati perioada', this.options);
    } else {
      const infractiuni = await this.httpService.getInfractiuni().toPromise();
      this.originalList = infractiuni;
      this.originalList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      for (const [i, v] of this.originalList.entries()) {
        this.originalList[i].infractiune_id = this.originalList[i].id;

        //set if has or not child
        let hasChild = this.originalList.filter(lista => lista.parent == this.originalList[i].id);
        if (hasChild.length) {
          this.originalList[i].hasChild = true;
        } else{
          this.originalList[i].hasChild = false;
        }

      }
      infractiuni.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      // agenti alesi
      if (!this.userList.length || this.userList.length == this.lis.length) {
        //if no users selectec...we select all of them
        this.agentiSelectati = 'Toti Agentii';
        for (const [i, v] of this.lis.entries()) {
          this.userList.push(v.id);
        }
      } else {
        this.agentiSelectati = '';
        for (const [i, v] of this.lis.entries()) {
          if (this.userList.includes(v.id)) {
            this.agentiSelectati += v.nume + ', '
          }
        }
        this.agentiSelectati = this.agentiSelectati.substring(0, this.agentiSelectati.length - 2);
      }

      //masini alese
      if (!this.masiniList.length || this.masiniList.length == this.masini.length) {
        //if no masina...it means all cars
        this.masiniSelectate = 'Toate Masinile';
      } else {
        this.masiniSelectate = '';
        for (const [i, v] of this.masini.entries()) {
          if (this.masiniList.includes(v.id)) {
            this.masiniSelectate += v.name + ', '
          }
        }
        this.masiniSelectate = this.masiniSelectate.substring(0, this.masiniSelectate.length - 2);
      }
      this.reportDetails.users = this.userList;
      this.reportDetails.masini = this.masiniList;
      this.apiService.getRaport(this.reportDetails).toPromise().then(async (response: any) => {
        await this.saveInReport(response);
        this.reportGenerated = true;

      }).catch(
        err => {
          console.log(err);
            this.alertService.error(err.error.message, this.options);

              }
            );
    }
  }

  async updateOriginalList(data) {
    for (const [i, v] of data.entries()) {
      if (v.infractiune_id !== 322) {
        let infractiuneIndex = this.originalList.findIndex(lista => lista.id == v.infractiune_id);
        if(!this.originalList[infractiuneIndex]) {
          console.log(v);
        }
        this.originalList[infractiuneIndex].hasValue = true;
        this.originalList[infractiuneIndex].infractiune_id = v.infractiune_id;
        if(this.originalList[infractiuneIndex].total_avertismente) {
          this.originalList[infractiuneIndex].total_avertismente = parseInt(this.originalList[infractiuneIndex].total_avertismente) + parseInt(v.total_avertismente);
        } else {
          if(v.total_avertismente) {
            this.originalList[infractiuneIndex].total_avertismente = parseInt(v.total_avertismente);
          }
        }
        if(this.originalList[infractiuneIndex].total_sanctiuni) {
          this.originalList[infractiuneIndex].total_sanctiuni = parseInt(this.originalList[infractiuneIndex].total_sanctiuni) + parseInt(v.total_sanctiuni);
        } else {
          if(v.total_sanctiuni) {
            this.originalList[infractiuneIndex].total_sanctiuni = parseInt(v.total_sanctiuni);
          }
        }
        if(this.originalList[infractiuneIndex].total_valoare) {
          this.originalList[infractiuneIndex].total_valoare = parseInt(this.originalList[infractiuneIndex].total_valoare) + parseInt(v.total_valoare);
        } else {
          if(v.total_valoare) {
            this.originalList[infractiuneIndex].total_valoare = parseInt(v.total_valoare);
          }
        }
        await this.updateParent(this.originalList[infractiuneIndex].parent, v);

      } else {
        const userData = await this.httpService.getUserById(v.agent).toPromise();
        v.agent = userData;
         this.mentiuni.push(v);
      }
    }
  }

  async updateParent(parentId, value){
    var parentIndex = this.originalList.findIndex(x => x.infractiune_id == parentId);
    if ((this.originalList[parentIndex].parent !== 0 || this.originalList[parentIndex].id == 54 || this.originalList[parentIndex].id == 263 || this.originalList[parentIndex].id == 264) && this.originalList[parentIndex].parent !== 229 && this.originalList[parentIndex].id !== 322) {
      if (!this.originalList[parentIndex].total_sanctiuni) {
        this.originalList[parentIndex].total_sanctiuni = 0;
      }
      if (!this.originalList[parentIndex].total_valoare) {
        this.originalList[parentIndex].total_valoare = 0;
      }
      if (!this.originalList[parentIndex].total_avertismente) {
        this.originalList[parentIndex].total_avertismente = 0;
      }

      this.originalList[parentIndex].hasValue = true;
      this.originalList[parentIndex].total_sanctiuni = parseInt(this.originalList[parentIndex].total_sanctiuni) + parseInt(value.total_sanctiuni);
      this.originalList[parentIndex].total_valoare = parseInt(this.originalList[parentIndex].total_valoare) + parseInt(value.total_valoare);
      this.originalList[parentIndex].total_avertismente = parseInt(this.originalList[parentIndex].total_avertismente) + parseInt(value.total_avertismente);

      if(this.originalList[parentIndex].parent !== 0) {
        await this.updateParent(this.originalList[parentIndex].parent, value);
      }
    } else {
      if (this.originalList[parentIndex].parent == 229) {
        var newParent = this.originalList.findIndex(x => x.id == 229);
        this.originalList[newParent].hasValue = true;
        this.originalList[parentIndex].hasValue = true;
      }
      if (this.originalList[parentIndex].parent == 0 && this.originalList[parentIndex].id != 322) {
        this.originalList[parentIndex].hasValue = true;
      }
    }
  }

  async saveInReport(data) {
    this.mentiuniRaport = true;
    this.mentiuni = [];
    await this.updateOriginalList(data);
    this.finalRaport = this.originalList.filter(lista => lista.parent == 0);
    for (const [key, value] of this.finalRaport.entries()) {

        let child = await this.getChild(value)
        this.finalRaport[key].child = child;
    }
      //now we delete the ones that have no values
      this.showReport = [];
      console.log(this.finalRaport);
      for (const [key, value] of this.finalRaport.entries()) {
        if (value.child.length) {
          if (value.id == 54) {
            if (value.total_sanctiuni) {
              this.totalContraventii = {
                denumire : 'Total Contraventii',
                total_avertismente: parseInt(value.total_avertismente),
                total_sanctiuni : parseInt(value.total_sanctiuni),
                total_valoare : parseInt(value.total_valoare)
              }
              this.showReport.push(this.totalContraventii);
              this.contraventiiIndex = this.showReport.length -1;
            }

          }
          if (value.id == 263) {
            if (value.total_sanctiuni) {
              if(this.contraventiiIndex > -1) {
                this.showReport[this.contraventiiIndex].total_avertismente = parseInt(this.showReport[this.contraventiiIndex].total_avertismente) + parseInt(value.total_avertismente);
                this.showReport[this.contraventiiIndex].total_sanctiuni = parseInt(this.showReport[this.contraventiiIndex].total_sanctiuni) + parseInt(value.total_sanctiuni);
                this.showReport[this.contraventiiIndex].total_valoare = parseInt(this.showReport[this.contraventiiIndex].total_valoare) + parseInt(value.total_valoare);
              } else {
                this.totalContraventii = {
                  denumire : 'Total Contraventii',
                  total_avertismente: parseInt(value.total_avertismente),
                  total_sanctiuni : parseInt(value.total_sanctiuni),
                  total_valoare : parseInt(value.total_valoare)
                }
                this.showReport.push(this.totalContraventii);
                this.contraventiiIndex = this.showReport.length -1;
              }
            }
          }
          await this.addToReport(value, 1);
        }
        if (value.id == 322 && value.mentiuni) {
          await this.addToReport(value, 1);
        }
      }
  }

  async addToReport(childValue, level) {
    if (childValue.child) {
      if (childValue.parent == 0) {
        if (!childValue.hasValue) {
          return;
        }
      } else {
          if (!childValue.hasValue) {
            return;
          }
        }
        childValue.level = level;
      if (childValue.name.charAt(0) != '-') {
        let lines = ''
        for (let i = 0; i < level; i++) {
          lines += '-';
        }

        childValue.name = lines +childValue.name;
      }

      this.showReport.push(childValue);

      level++;
      for (const [k, v] of childValue.child.entries()) {
        if (v.child && v.child.length) {
          await this.addToReport(v, level);
        } else {
          if (v.total_sanctiuni) {
            v.level = level;
            if (v.name.charAt(0) != '-') {
              let lines = ''
              for (let i = 0; i < level; i++) {
                lines += '-';
              }

              v.name = lines + v.name;
            }
            this.showReport.push(v);
          }
        }
      }
    }
  }

  async getChild(child) {
    let tmpChild = this.originalList.filter(lista => (lista.parent == child.id && lista.hasChild == false && lista.total_sanctiuni));
    let tmpLowerChildren = this.originalList.filter(lista => (lista.parent == child.id && lista.hasChild == true));
    for (const [key, value] of tmpLowerChildren.entries()) {
      let child = await this.getChild(value);
      if (child.length) {
        tmpLowerChildren[key].child = child;
      }
    }
    //now we remove the childless
    let report = tmpChild.concat(tmpLowerChildren);
    return report;
  }

  changeSchimb(value) {
    if (value) {
      this.reportDetails.schimb = value;
      this.schimbSelectate = value;
    } else {
      this.reportDetails.schimb = 0;
      this.schimbSelectate = 'Toate schimburile';
    }

  }

  onChange(value, period) {
    this.reportDetails.start = new Date(2021, 1, 1);
    this.reportDetails.end = new Date(2021, 12, 31);


    if (this.selectedDateStart) {
      this.dateSelected = true;
    } else {
      this.reportGenerated = false;
      this.data = [];
    }

  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  async downloadAsPDF() {
    this.mentiuniRaport = true;
    await this.sleep(500);
    this.printing = true;
    let printContents, popupWin;
    printContents = document.getElementById("pdfTable").innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
  <html>
    <head>
      <title>Raport</title>

    </head>
<body onload="window.print();window.close()"><table class="table table-bordered">${printContents}</table></body>
  </html>`
    );
    this.printing = false;
    popupWin.document.close();

  }

  async  downloadAsPDFFaraMentiuni() {
    this.mentiuniRaport = false;
    await this.sleep(500);
    let printContents, popupWin;
    this.printing = true;
    printContents = document.getElementById("pdfTable").innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
  <html>
    <head>
      <title>Raport</title>

    </head>
<body onload="window.print();window.close()"><table class="table table-bordered">${printContents}</table></body>
  </html>`
    );
    this.printing = false;
    popupWin.document.close();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  changeStructura(structura) {
    if(structura != 'Toate Structurile') {
      this.lis = this.allUsersList.filter(function(str) {return str.departament.includes(structura);});
    } else {
      this.lis = this.allUsersList;
    }

  }

  changeYear(year) {

  }
}
