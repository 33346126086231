import { Component, destroyPlatform, ElementRef, OnInit } from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import { first } from 'rxjs/operators';
import {AlertService} from '../_alert';
import {Observable, of as observableOf} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  editUserForm = new FormGroup({
    nume: new FormControl(''),
    username: new FormControl('', Validators.compose([
      Validators.required,
    ])),
    departament: new FormControl('', Validators.required),
    numar_insigna: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    password2: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
  });

  locatii = ['Serviciul Rutier Bihor', 'Biroul Drumuri Nationale si Europene', 'Biroul Rutier Oradea','Biroul Rutier Beius', 'Biroul Rutier Marghita', 'Biroul Rutier Salonta', 'Compatimentul Rutier Alesd', 'Politie Statiune Baile Felix'];

  id: string;
  display = false;
  currentRole: any;

  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };

  userData: any;
  isSupraveghetor = false;


  source = [];

  constructor(private httpService: ApiService, private router: Router, private elementRef:ElementRef, private route: ActivatedRoute, public alertService: AlertService, public authService: AuthService, private tokenStorageService: TokenStorageService,) {

  }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.params['id'];
    this.userData = this.tokenStorageService.getUser();
    const userData = await this.httpService.getUserById(this.id).toPromise();
    this.currentRole = userData.roles[0].role_id;
          this.display = true;
        delete userData.password;
        if (this.currentRole == 4 || this.currentRole == 1) {
          this.isSupraveghetor = true;
          userData.departament = '';
        }
    this.editUserForm.patchValue(userData);
    this.editUserForm.setValue({
      nume: userData.nume,
      role: this.currentRole,
      username: userData.username,
      departament: userData.departament,
      numar_insigna: userData.numar_insigna,
      email: userData.email,
      password:'',
      password2: '',
    });
  }

  onSubmit() {
       this.httpService.updateUser(this.editUserForm.value, this.id).toPromise().then(
         response => {
           this.alertService.success('Utilizatorul a fost actualizat cu success', this.options);
           window.scroll(0, 0);
        this.router.navigateByUrl('/user');
      }).catch(
        err => {
          this.alertService.error(err.error.message, this.options);
          window.scroll(0, 0);
            }
          );
  }

  checkCurrentRole(roleId) {
    if (roleId == this.currentRole) return true;
    return false;
  }

  setRole(value) {
    if (value == 4 || value == 1) {
      this.isSupraveghetor = true;
    } else {
      this.isSupraveghetor = false;
    }
  }
}
