import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenStorageService} from '../_services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  httpOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  };
  httpOptionsFile = {
    headers: new HttpHeaders()
      .set('Content-Type',  'multipart/form-data')
  };

  getUsers() {
    return this.http.get<any>(environment.baseUrl + '/users', this.httpOptions);
  }

  getUserById(id) {
    return this.http.get<any>(environment.baseUrl + '/users/' + id, this.httpOptions);
  }

  getInfractiuneById(id) {
    return this.http.get<any>(environment.baseUrl + '/infractiune/' + id, this.httpOptions);
  }

  addUser(formData) {
    return this.http.post(environment.baseUrl + '/users', formData, this.httpOptions);
  }

  addInfractiune(formData) {
    return this.http.post(environment.baseUrl + '/infractiune', formData, this.httpOptions);
  }

  addRaport(formData) {
    return this.http.post(environment.baseUrl + '/infractiune/raport', formData, this.httpOptions);
  }

  getRaport(data) {
    return this.http.post(environment.baseUrl + '/infractiune/getRaport', data, this.httpOptions);
  }

  getInfractiuni() {
    return this.http.get<any>(environment.baseUrl + '/infractiune', this.httpOptions);
  }

  updateUser(formData, id) {
    return this.http.put(environment.baseUrl + '/users/' + id, formData, this.httpOptions);
  }

  updateInfractiune(formData, id) {
    return this.http.put(environment.baseUrl + '/infractiune/' + id, formData, this.httpOptions);
  }

  deleteInfractiune(infractiuneId) {
    return this.http.delete(environment.baseUrl + '/infractiune/'+infractiuneId, this.httpOptions);
  }

  getUserRole(id) {
    return this.http.get<any>(environment.baseUrl + '/userRole/' + id, this.httpOptions);
  }




  login(credentials) {

    return this.http.post(environment.baseUrl + '/auth/signin', credentials, this.httpOptions);
  }



  deleteUser(userId) {
    return this.http.delete(environment.baseUrl + '/users/'+userId, this.httpOptions);
  }



  updateUserPassword(data) {
    const userDetails = this.tokenStorageService.getUser();
    return this.http.put<any>(environment.baseUrl + '/users/updatePassword/' + userDetails.id, data, this.httpOptions);
  }

  getRoles() {
    return this.http.get<any>(environment.baseUrl + '/users/roles', this.httpOptions);
  }

  stergereRaport(formData) {
    return this.http.post(environment.baseUrl + '/infractiune/stergere-raport', formData, this.httpOptions);
  }

  deleteCompletlyRaport(formData) {
    return this.http.post(environment.baseUrl + '/infractiune/delete-report', formData, this.httpOptions);
  }

}
