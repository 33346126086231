import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {AlertService} from '../_alert';
import {TokenStorageService} from '../_services/token-storage.service';

@Component({
  selector: 'app-contul-meu',
  templateUrl: './contul-meu.component.html',
  styleUrls: ['./contul-meu.component.css']
})
export class ContulMeuComponent implements OnInit {
  user_id: any;
  userData: any;
  renderPage = false;
  editPasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    password2: new FormControl('', Validators.required),
  });
  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };
  constructor(private route: ActivatedRoute, private httpService: ApiService, private tokenStorageService: TokenStorageService,public alertService: AlertService,private router: Router) { }

  ngOnInit(): void {

    if (!this.user_id) {
      this.user_id = this.tokenStorageService.getUser().id;
    }
    this.httpService.getUserById(this.user_id).toPromise().then(userDetails => {
      this.userData = userDetails;
      this.renderPage = true;
    });
  }

  onSubmit() {
    this.httpService.updateUserPassword(this.editPasswordForm.value).toPromise().then(
         response => {
           this.alertService.success('Utilizatorul a fost actualizat cu success', this.options);
           window.location.reload();
      }).catch(
        err => {
          this.alertService.error(err.error.message, this.options);
            }
          );

  }

}
