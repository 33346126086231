<!-- Page Wrapper -->
<div id="wrapper">

  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" *ngIf="isLoggedIn">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" [routerLink]="['/']">
      <img height="65" width="65" src="/assets/img/logo.jpeg">
      <div class="sidebar-brand-text mx-3">Politia Rutiera Bihor</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/']">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Adaugare Raport</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      Meniu
    </div>

    <!-- Nav Item - Charts -->
    <li class="nav-item" *ngIf="authService.checkAccess(1) || authService.checkAccess(2)">
      <a class="nav-link" [routerLink]="['/user']" >
        <i class="fas fa-fw fa-user"></i>
        <span>Utilizatori</span></a>
    </li>

    <!-- Nav Item - Charts -->
    <li class="nav-item" *ngIf="authService.checkAccess(1)">
      <a class="nav-link" [routerLink]="['/infractiune']">
        <i class="fas fa-fw fa-user"></i>
        <span>Actiuni</span></a>
    </li>

    <!-- Nav Item - Charts -->
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/raport']">
        <i class="fas fa-fw fa-user"></i>
        <span>Raport</span></a>
    </li>

    <!-- Nav Item - Charts -->
    <li class="nav-item" *ngIf="authService.checkAccess(1) || authService.checkAccess(2)">
      <a class="nav-link" [routerLink]="['/raport-anual']">
        <i class="fas fa-fw fa-user"></i>
        <span>Raport Anual</span></a>
    </li>

    <!-- Nav Item - Charts -->
    <li class="nav-item" *ngIf="!authService.checkAccess(3)">
      <a class="nav-link" [routerLink]="['/raport-status']" >
        <i class="fas fa-fw fa-user"></i>
        <span>Status Rapoarte</span></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" href="#" [routerLink]="['/contul-meu']">
        <i class="fas fa-fw fa-user"></i>
        <span>Contul Meu</span></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" href="#" (click)="logout()">
        <i class="fas fa-fw fa-sign-out-alt"></i>
        <span>LogOut</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">


  </ul>
  <!-- End of Sidebar -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar -->
      <nav class="navbar page-wrapper navbar-default sticky-top navbar-expand navbar-light bg-white topbar mb-4 static-top shadow" *ngIf="isLoggedIn">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" (click)="toggleMenu()">
          <i class="fa fa-bars"></i>
        </button>

      </nav>
      <alert></alert>
      <router-outlet></router-outlet>
      <app-footer></app-footer>


<!-- Bootstrap core JavaScript-->
<script src="../../assets/vendor/jquery/jquery.min.js"></script>
<script src="../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

<!-- Core plugin JavaScript-->
<script src="../../assets/vendor/jquery-easing/jquery.easing.min.js"></script>

<!-- Custom scripts for all pages-->
<script src="../../assets/js/sb-admin-2.js"></script>

<script type="text/javascript"
  src="https://ajax.aspnetcdn.com/ajax/jquery.validate/1.7/jquery.validate.min.js"></script>
