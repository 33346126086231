<!-- Begin Page Content -->
<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800">Raport Activitate Anuala</h1>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-body">
      <label>Alegeti Anul</label>
      <div class="form-group col-sm-3 mb-3 mb-sm-2">
      </div>
      <select class="form-control">
        <option [value]="2021">
          2021
        </option>
      </select>
      <div class="row">

          <div class="col-sm-3 mb-3 mb-sm-0" *ngIf="authService.checkAccess(1)  || authService.checkAccess(4)">
            <div class="form-group">
              <label for="inputEmail4">Structura</label>
              <select class="form-control" (change)="changeStructura($event.target.value)">
                <option *ngFor='let loc of locatii' [value]="loc">
                  {{loc}}
                </option>
              </select>
            </div>
          </div>
      </div>

      <button type="submit" (click)="generateReport()" class="btn btn-success btn-sm float-right mr-1">
        Generare Raport</button>
        <button class="btn btn-warning btn-sm float-right mr-1" (click)="downloadAsPDF()" *ngIf="reportGenerated">Descarca</button>
    </div>

  </div>

  <div class="card shadow mb-4" *ngIf="reportGenerated" >

    </div>
</div>
