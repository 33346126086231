import { Component, ElementRef, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {AlertService} from '../_alert';

@Component({
  selector: 'app-add-infractiune',
  templateUrl: './add-infractiune.component.html',
  styleUrls: ['./add-infractiune.component.css']
})
export class AddInfractiuneComponent implements OnInit {

  addInfractiuneForm = new FormGroup({
    name: new FormControl(''),
    structura: new FormControl(''),
  });

  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };

  id: string;
  locatii = ['Toate Structurile', 'Serviciul Rutier Bihor', 'Biroul Drumuri Nationale si Europene', 'Biroul Rutier Oradea', 'Biroul Rutier Beius', 'Biroul Rutier Marghita', 'Biroul Rutier Salonta', 'Compatimentul Rutier Alesd', 'Politie Statiune Baile Felix'];

  constructor(private httpService: ApiService, private router: Router, private elementRef:ElementRef, public alertService: AlertService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.addInfractiuneForm.controls['structura'].setValue('Toate Structurile');
  }

  onSubmit() {
    window.scroll(0, 0);
    this.id = this.route.snapshot.params['id'];
    this.addInfractiuneForm.value.parent = this.id;
    this.httpService.addInfractiune(this.addInfractiuneForm.value).toPromise().then(
      response => {
          this.alertService.success('Adaugat cu succes!', this.options);
          this.router.navigateByUrl('/infractiune');
        }).catch(
          err => {
            this.alertService.error(err.error.message, this.options);
          }
        );

  }

}
