import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import { UserComponent } from './user/user.component';
import {AlertModule} from './_alert';
import {LoginComponent} from './login/login.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from './confirmation-dialog/confirmation-dialog.service';
import { ContulMeuComponent } from './contul-meu/contul-meu.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { InfractiuneComponent } from './infractiune/infractiune.component';
import { AddInfractiuneComponent } from './add-infractiune/add-infractiune.component';
import { EditInfractiuneComponent } from './edit-infractiune/edit-infractiune.component';
import { RaportComponent } from './raport/raport.component';
import { StatusRaportComponent } from './status-raport/status-raport.component';
import { AnualRaportComponent } from './anual-raport/anual-raport.component';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMMM YYYY',
  },
  display: {
    dateInput: 'DD MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AddUserComponent,
    EditUserComponent,
    UserComponent,
    LoginComponent,
    ConfirmationDialogComponent,
    ContulMeuComponent,
    InfractiuneComponent,
    AddInfractiuneComponent,
    EditInfractiuneComponent,
    RaportComponent,
    StatusRaportComponent,
    AnualRaportComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    AlertModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatInputModule,
    AngularDualListBoxModule,
  ],
  providers: [{provide: APP_BASE_HREF, useValue : './' }, authInterceptorProviders, DatePipe, ConfirmationDialogService, { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  bootstrap: [AppComponent],
  entryComponents: [ ConfirmationDialogComponent ]
})
export class AppModule { }
