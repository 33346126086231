import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {Subject} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-infractiune',
  templateUrl: './infractiune.component.html',
  styleUrls: ['./infractiune.component.css']
})
export class InfractiuneComponent implements OnInit {

  li: any;
  lis = [];
  element: any;
  renderPage = true;
  parent = 0;
  originalList = [];
  previousCategory = 0;
  breadcrumb = [];

  constructor(private router: Router, private httpService: ApiService, public authService: AuthService, private confirmationDialogService: ConfirmationDialogService, private _location: Location) { }

  async ngOnInit(): Promise<void> {
    if (this.authService.checkAccess(3)) {
      this._location.back();
    }

    window.scroll(0, 0);
    const infractiuni = await this.httpService.getInfractiuni().toPromise();
    this.originalList = infractiuni;
    this.originalList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.showOnlySelectedCategory();
  }

  showOnlySelectedCategory() {
    this.lis = this.originalList.filter(lista => lista.parent == this.parent);
    if (this.parent == 0) {
      for (var k in this.lis) {
        if (this.lis.hasOwnProperty(k)) {
           this.lis[k].name = this.lis[k].name.toUpperCase();
        }
      }
    }
  }

  goToAddInfractiuni(id) {
    this.router.navigateByUrl('/add-infractiune/'+id);
  }

  goToEditInfractiune(id) {
    this.router.navigateByUrl('/edit-infractiune/' + id);
  }

  showSubCategories(newParent) {
    this.previousCategory = this.parent;
    this.parent = newParent;
    this.showOnlySelectedCategory();
  }

  public openConfirmationDialog(infractiuneId, index) {
    this.confirmationDialogService.confirm('Te rog confirma!', 'Esti sigur ca vrei sa modifici statusul infractiunii infractiunea?')
      .then((confirmed) => {
        if (confirmed) {
          this.httpService.deleteInfractiune(infractiuneId).subscribe((response => { }))
          this.lis.splice(index, 1);
        }
        this.rerender();
      })
    .catch(() => console.log(''));
  }

  public changeInfractiuneStatus(infractiuneId, value) {
    let changeInfractiune: any = this.originalList.filter(lista => lista.id == infractiuneId);
    changeInfractiune[0].inactive = value;
    this.httpService.updateInfractiune(changeInfractiune[0], infractiuneId).toPromise().then(
      response => {
        window.scroll(0, 0);
     this.router.navigateByUrl('/infractiune');
   })
  }

  rerender(): void {
  }

  // public updateList(search) {
  //   search = search.toLowerCase();
  //   let updatedList = this.originalList;
  //   updatedList = updatedList.filter(function(str) {
  //     const tmp = str.name.toLowerCase();
  //     if (tmp.includes(search)) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   this.lis = updatedList;
  // }

  async updateList(search) {
    if (search && this.parent) {
      let updatedList = this.originalList.filter(infr => infr.parent == this.parent);
      search = search.toLowerCase();
      updatedList = updatedList.filter(function(str) {
        const tmp = str.name.toLowerCase();
        if (tmp.includes(search)) {
          return true;
        }
        return false;
      });
      this.lis = updatedList;
    } else {
      this.lis = this.originalList.filter(lista => lista.parent == this.parent);
    }

  }

}
