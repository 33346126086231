import { Component, ElementRef, OnInit } from '@angular/core';
import {FormArray, FormControl,FormGroup,Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable, of as observableOf} from 'rxjs';
import {ApiService} from '../services/api.service';
import {AlertService} from '../_alert';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})

export class AddUserComponent implements OnInit {
  locatii = ['Serviciul Rutier Bihor', 'Biroul Drumuri Nationale si Europene', 'Biroul Rutier Oradea', 'Biroul Rutier Beius', 'Biroul Rutier Marghita', 'Biroul Rutier Salonta', 'Compatimentul Rutier Alesd', 'Politie Statiune Baile Felix'];
  isSupraveghetor = true;
  addUserForm = new FormGroup({
    nume: new FormControl(''),
    username: new FormControl('', Validators.compose([
      Validators.required,
    ])),
    departament: new FormControl('', Validators.required),
    numar_insigna: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    password2: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
  });
  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };

  userData: any;

  noStructure = false;

  constructor(private httpService: ApiService, private router: Router, private elementRef:ElementRef, public alertService: AlertService, private tokenStorageService: TokenStorageService, public authService: AuthService) {
  }

  ngOnInit(): void {
    this.userData = this.tokenStorageService.getUser();
  }

  onSubmit() {
    window.scroll(0, 0);
    if (this.addUserForm.value.role == '2' || this.addUserForm.value.role == '3') {
      if (!this.addUserForm.value.departament.length) {
        this.noStructure = true;
        this.alertService.error('Va rugam alegeti o structura', this.options);
      } else {
        this.noStructure = false;
      }
    } else {
      this.noStructure = false;
    }
    if (!this.noStructure) {
      this.httpService.addUser(this.addUserForm.value).toPromise().then(
        response => {
          this.alertService.success('Adaugat cu succes!', this.options);
          this.router.navigateByUrl('/user');
        }).catch(
          err => {
            this.alertService.error(err.error.message, this.options);
          }
        );
    }


  }

  setRole(value) {
    if (value == 4 || value == 1) {
      this.isSupraveghetor = true;
    } else {
      this.isSupraveghetor = false;
    }
  }
}
