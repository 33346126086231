<!-- Begin Page Content -->
<div class="container-fluid">

  <!-- Page Heading -->
  <h1 class="h3 mb-2 text-gray-800">Infractiuni</h1>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <button type="button" class="btn btn-primary pull-right" (click)="goToAddInfractiuni(parent);">Adaugare</button>
      <button id="0" type="button" class="btn btn-success ml-1 pull-right" (click)="showSubCategories(0)"
        *ngIf="parent">Inapoi la listare</button>
        <div class="card-header py-3">
          Cautare: <input type="text" (input)="updateList($event.target.value)">
        </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
          <thead>
            <tr>
              <th>Denumire</th>
              <th>Actiuni</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Denumire</th>
              <th>Actiuni</th>
            </tr>
          </tfoot>
          <tbody>
            <tr *ngFor="let e of lis;let i = index" [attr.data-index]="i">
              <td>{{ e.name }}</td>
              <td>
                <button id="{{ e.id }}" type="button" class="btn btn-sm btn-success mr-1  mt-1 deleteButton"
                  (click)="showSubCategories(e.id)">Selectare</button>
                <a routerLink='/edit-infractiune/{{ e.id }}' class="btn btn-sm btn-primary mr-1 mt-1">Editare</a>
                <button id="{{ e.id }}" type="button" class="btn btn-sm btn-success mt-1 deleteButton"
                  (click)="changeInfractiuneStatus(e.id, 0)" *ngIf="e.inactive">Activare</button>
                  <button id="{{ e.id }}" type="button" class="btn btn-sm btn-danger mt-1 deleteButton"
                  (click)="changeInfractiuneStatus(e.id, 1)" *ngIf="!e.inactive">Dezactivare</button>
              </td>
            </tr>
          </tbody>
        </table>
    </div>

  </div>
</div>
