<p>status-raport works!</p>
<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Page Heading -->
    <h1 class="h3 mb-2 text-gray-800">Status Rapoarte</h1>
  
        <button type="button" (click)="picker1.open()" class="btn btn-primary btn-sm">
          Alege Data</button>
        <mat-form-field class="example-full-width" appearance="fill">
          <input matInput [matDatepicker]="picker1"  name="data"
            (dateChange)="changeDate($event)"readonly>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <div class="form-group row">
          <div class="col-sm-3 mb-3 mb-sm-0">
            <div class="form-group" *ngIf="authService.checkAccess(1) || authService.checkAccess(4)">
              <label for="inputEmail4">Structura</label>
              <select class="form-control" (change)="changeStructura($event.target.value)">
                <option *ngFor='let loc of locatii' [value]="loc">
                  {{loc}}
                </option>
              </select>
            </div>
            <div class="form-group" *ngIf="authService.checkAccess(2)">
              <label for="inputEmail4">Structura</label>
              <select class="form-control" (change)="changeStructura($event.target.value)">
                <option [value]="userData.structura">
                  {{userData.structura}}
                </option>
              </select>
            </div>
          </div>
        </div>
  
        <div *ngIf="selectedDate">
            <button type="button" class="btn btn-primary pull-right" (click)="generateStatusReport();">Verificare Raport</button>
          </div>
  
    <!-- DataTales Example -->
    <div class="card shadow mb-4">
  
        <div class="table-responsive" *ngIf="reportGenerated">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Nr.</th>
                <th>Agent</th>
                <th >Status Raport</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Nr.</th>
                <th>Agent</th>
                <th >Status Raport</th>
              </tr>
            </tfoot>
            <tbody>
              <tr *ngFor="let e of lis;let i = index" [attr.data-index]="i">
                <td>{{ i+1 }}</td>
                <td>{{ e.nume }}</td>
                <td >
                  {{e.raport ? 'Are Raport' : 'Nu are raport'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  
</div>
  