import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {AlertService} from '../_alert';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  step = 1;
  lis = [];
  finalRaport = [];
  selectedForReport = [{}];
  showReport = [];
  saveReport = [];
  currentForReport = {'denumire': '', 'total_sanctiuni': 0, 'total_valoare': 0, 'index': 0, 'total_avertismente': 0, 'parent': 0, 'id': 0, 'mentiuni': '', 'masina': 0, 'infractiune_id': 0};
  selectedDate = null;
  selectedSchimb = null;
  minDate = new Date();
  maxDate = new Date();
  stepOneData = false;
  parent = 0;
  mainParent = 0;
  originalList = [];
  disableSave = 0;
  hasValue = false;
  hasMentiuni = false;
  previousCat = 0;
  masinaReport = [];
  mentiuni = [];
  inputName: string;
  inputNameTop: string

  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };

  masinaSelectata = 0;
  raportMasina = false;
  breadcrumb = [];

  yesterday: string;
  today: string;
  deletedReports: any;
  deletedDates = [];
  haveDeletedReports = false;
  updatedList = [];
  userRole: number;
  selectedAgent: number;
  agentList: any;
  originalReport = false;

  generatedReport = [];
  mentiuniRaport = true;
  totalContraventii = {denumire: '', total_sanctiuni: 0, total_avertismente: 0, total_valoare: 0, hasChild: 1};
  contraventiiIndex = -1;
  structuraUser = 'Toate Structurile';
  hasAllData = true;

  permiseAutomatRetinute = [
    {'contraventieId': 86, 'permis': 406},
    {'contraventieId': 87, 'permis': 208},
    {'contraventieId': 88, 'permis': 209},
    {'contraventieId': 76, 'permis': 196},
    {'contraventieId': 27, 'permis': 211},
    {'contraventieId': 90, 'permis': 212},
    {'contraventieId': 75, 'permis': 202},
    {'contraventieId': 323, 'permis': 197},
    {'contraventieId': 74, 'permis': 407},
    {'contraventieId': 71, 'permis': 407},
    {'contraventieId': 67, 'permis': 407},
    {'contraventieId': 65, 'permis': 407},
    {'contraventieId': 69, 'permis': 407},
    {'contraventieId': 62, 'permis': 210},
    {'contraventieId': 210, 'permis': 59},
    {'contraventieId': 7, 'permis': 199},
    {'contraventieId': 78, 'permis': 199},
    {'contraventieId': 77, 'permis': 206},
    {'contraventieId': 81, 'permis': 203},
    {'contraventieId': 79, 'permis': 203},
    {'contraventieId': 58, 'permis': 203},
    {'contraventieId': 73, 'permis': 203},
    {'contraventieId': 72, 'permis': 203},
    {'contraventieId': 70, 'permis': 203},
    {'contraventieId': 68, 'permis': 203},
    {'contraventieId': 66, 'permis': 203},
    {'contraventieId': 85, 'permis': 200},
    {'contraventieId': 57, 'permis': 198},
    {'contraventieId': 599, 'permis': 407},
    {'contraventieId': 600, 'permis': 203},
    {'contraventieId': 602, 'permis': 608},
    {'contraventieId': 607, 'permis': 609},
    {'contraventieId': 605, 'permis': 610},
    {'contraventieId': 604, 'permis': 612},
    {'contraventieId': 603, 'permis': 611},
    {'contraventieId': 80,  'permis': 199},
  ];

  constructor(
    private router: Router,
    private httpService: ApiService,
    public alertService: AlertService,
    private ref: ChangeDetectorRef,
    public configService: AlertService,
    public authService: AuthService,
    private tokenStorage: TokenStorageService
  ) { }

  async ngOnInit() {
    this.userRole = this.tokenStorage.getUser().roles;
    if (this.userRole == 4) {
      this.router.navigate(['raport']);
    }
    const data = await this.httpService.getUsers().toPromise();
    if(this.userRole > 1) {
      this.selectedAgent = this.tokenStorage.getUser().id;
      this.structuraUser = this.tokenStorage.getUser().structura;
      //get deleted raports
      let reportDetails = {'start': '', 'end': '', 'users': [this.tokenStorage.getUser().id], 'schimb': 0, 'masini': [], 'stergere': 1};
      this.deletedReports = await this.httpService.getRaport(reportDetails).toPromise();
      this.deletedDates = [];
      if (this.deletedReports[0]) {
        for (const [key, value] of this.deletedReports.entries()) {
          if (!this.deletedDates.includes(value.data)) {
            this.haveDeletedReports = true;
            this.deletedDates.push(value.data);
          }
        }

      }
      this.minDate.setDate(this.maxDate.getDate() - 1);
      reportDetails = {'start': this.formatDate(this.minDate), 'end': '', 'users': [this.tokenStorage.getUser().id], 'schimb': 0, 'masini': [], 'stergere': -1};

      let min = await this.httpService.getRaport(reportDetails).toPromise();
      if (!min[0]) {
        this.yesterday = this.formatDate(this.minDate);
      }
      this.today = this.formatDate(this.maxDate);
      if(this.userRole == 2) {
        const structura = this.tokenStorage.getUser().structura;
        this.agentList = data.filter((agenti) => agenti.departament == structura);
      } else {
        this.agentList = data.filter((agenti) => agenti.id == this.selectedAgent);
      }
      this.agentList.sort((a, b) => (a.nume > b.nume) ? 1 : ((b.nume > a.nume) ? -1 : 0));

    } else {
      this.selectedAgent = this.tokenStorage.getAgent();
      //for administrator, he can add raport for anyone on any date
      this.minDate.setDate(this.maxDate.getDate() - 365);
      this.agentList = data;
      this.agentList.sort((a, b) => (a.nume > b.nume) ? 1 : ((b.nume > a.nume) ? -1 : 0));
    }


    window.scroll(0, 0);
    delete (this.selectedForReport[0]);
    this.selectedDate = this.tokenStorage.getData();
    this.selectedSchimb = this.tokenStorage.getTura();
    if (this.selectedDate && this.selectedSchimb) {
      await this.setStep(2);
    }
    if(!this.originalList || !this.originalList.length) {
      await this.getOriginalList();
    }

    this.showOnlySelectedCategory(0);
  }

  async getOriginalList() {
    const infractiuni = await this.httpService.getInfractiuni().toPromise();
    if(this.userRole > 1) {
      this.originalList = infractiuni.filter(infractiune => {return (infractiune.inactive == 0 && (infractiune.structura == 'Toate Structurile' || infractiune.structura == this.structuraUser))});
    } else {
      this.originalList = infractiuni.filter(infractiune => {return (infractiune.inactive == 0)});
    }

    this.originalList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    for (const [i, v] of this.originalList.entries()) {
      this.originalList[i].infractiune_id = this.originalList[i].id;

      //set if has or not child
      let hasChild = this.originalList.filter(lista => lista.parent == this.originalList[i].id);
      if (hasChild.length) {
        this.originalList[i].hasChild = true;
      } else{
        this.originalList[i].hasChild = false;
      }

    }
  }

  showOnlySelectedCategory(parent) {
    if (this.masinaSelectata == 0) {
      this.masinaSelectata = parent;
    }
    if (parent == 264 || parent == 265 || parent == 240) {
      this.inputName = 'Infractiuni:';
      this.inputNameTop = 'Infractiuni:';
      this.hasValue = false;
      this.hasMentiuni = false;
      this.masinaSelectata = null;
    }
    if (parent == 54 || parent == 263) {
      this.inputName = 'Contraventii:';
      this.inputNameTop = 'Contraventii:';
      this.hasValue = true;
      this.hasMentiuni = false;
      this.masinaSelectata = null;
    }
    if (parent == 229) {
      this.raportMasina = true;
      this.hasValue = false;
      this.hasMentiuni = false;
      this.masinaSelectata = 0;
    } else {
      this.raportMasina = false;

      this.inputNameTop = '';
    }
    if(parent == 322 || parent == 0) {
      this.hasValue = false;
      this.hasMentiuni = true;
      this.inputName = '';
      this.inputNameTop = '';
      this.masinaSelectata = null;
    }

    this.lis = this.originalList.filter(lista => lista.parent == parent);
    for (var i = 0, l = this.lis.length; i < l; i++) {
      let hasChild = this.originalList.filter(lista => lista.parent == this.lis[i].id);
      if (hasChild.length) {
        this.lis[i].hasChild = true;
      } else {
        this.lis[i].hasChild = false;
      }
    }
    console.log(this.lis);
  }

  async setStep(step) {
    if (step == 2 && this.step == 4) {
      // window.location.reload();
    }
    if (step == 1) {
      this.tokenStorage.saveReport(false);
      this.tokenStorage.saveData(false);
      this.tokenStorage.saveTura(false);
      this.selectedDate = false;
      this.selectedSchimb = false;
      this.originalReport = false;
      this.originalList = [];
      await this.getOriginalList();
    }
    if (step == 4) {
      await this.getOriginalList();
      await this.saveInReport(this.generatedReport);
    }
    if (step == 2) {
      if(!this.originalList.length) {
        await this.getOriginalList();
      }

      // luam raportul pe ziua respectiva si il adaugam la raport
      if(!this.originalReport) {
          let reportDetails = {'start': this.selectedDate, 'end': '', 'users': [this.selectedAgent], 'schimb': 0, 'masini': []};
          this.httpService.getRaport(reportDetails).toPromise().then(async (response: any) => {
            this.originalReport = true;

            for (const [index, value] of response.entries()) {
              if(value.infractiune_id == 322) {
                this.changeMentiuni(value.mentiuni, value.infractiune_id);
              } else {
                if(value.total_avertismente) {
                  this.changeAvertismente(value.total_avertismente, value.infractiune_id);
                }
                if(value.total_sanctiuni) {
                  this.changeSanctiuni(value.total_sanctiuni, value.infractiune_id);
                }
                if(value.total_valoare) {
                  this.changeValoare(value.total_valoare, value.infractiune_id);
                }
              }
            }
          });
        }

        this.generatedReport = this.tokenStorage.getReport();
        if(!this.generatedReport) {
          this.generatedReport = [];
        }
    }
    this.step = step;
  }

  async changeSanctiuni(value, id, contraventie = 0) {
    var foundIndex = this.generatedReport.findIndex(x => x.infractiune_id == id);
    var originalValues = this.originalList.findIndex(x => x.id == id);
    this.originalList[originalValues].total_sanctiuni = value;

    if(foundIndex>-1) {
      this.generatedReport[foundIndex].total_sanctiuni = value;
      if(contraventie && this.generatedReport[foundIndex].total_sanctiuni) {

        if(this.generatedReport[foundIndex].total_avertismente || this.generatedReport[foundIndex].total_valoare) {
          this.generatedReport[foundIndex].missingValue = 0;
        } else {
          this.generatedReport[foundIndex].missingValue = 1;
        }
      }

    } else {
      let tmp: any = {};

      tmp.denumire = this.originalList[originalValues].name;
      tmp.infractiune_id = this.originalList[originalValues].id;
      if (this.masinaSelectata) {
        tmp.masina = this.masinaSelectata;
      }
      tmp.parent = this.parent;
      tmp.total_sanctiuni = value;
      tmp.total_avertismente = 0;
      tmp.total_valoare = 0;
      tmp.mentiuni = '';
      if(contraventie) {
        tmp.missingValue = 1;
      } else {
        tmp.missingValue = 0;
      }
      this.generatedReport.push(tmp);
    }
    this.adaugarePermiseRetinute(id, value);
    this.tokenStorage.saveReport(this.generatedReport);
  }

  async changeValoare(value, id, contraventie = 0) {
    var foundIndex = this.generatedReport.findIndex(x => x.infractiune_id == id);
    var originalValues = this.originalList.findIndex(x => x.id == id);
    this.originalList[originalValues].total_valoare = value;
    if(foundIndex>-1) {
      this.generatedReport[foundIndex].total_valoare = value;
      if(contraventie && this.generatedReport[foundIndex].total_sanctiuni) {

        if(this.generatedReport[foundIndex].total_avertismente || this.generatedReport[foundIndex].total_valoare) {
          this.generatedReport[foundIndex].missingValue = 0;
        } else {
          this.generatedReport[foundIndex].missingValue = 1;
        }
      }
    } else {
      let tmp: any = {};

      tmp.denumire = this.originalList[originalValues].name;
      tmp.infractiune_id = this.originalList[originalValues].id;
      if (this.masinaSelectata) {
        tmp.masina = this.masinaSelectata;
      }
      tmp.parent = this.parent;
      tmp.total_sanctiuni = 0;
      tmp.total_avertismente = 0;
      tmp.total_valoare = value;
      tmp.mentiuni = '';
      if(contraventie) {
        if( tmp.total_avertismente || tmp.total_valoare) {
          tmp.missingValue = 0;
        } else {
          tmp.missingValue = 1;
        }

      } else {
        tmp.missingValue = 0;
      }
      this.generatedReport.push(tmp);
    }
    this.tokenStorage.saveReport(this.generatedReport);
  }

  async changeAvertismente(value, id, contraventie = 0) {
    if(value > 99){
      alert('Valoare Maxima 99, va rugam ajustati numarul de avertismente');
      value = 99;
    }
    console.log(value);
    var foundIndex = this.generatedReport.findIndex(x => x.infractiune_id == id);
    var originalValues = this.originalList.findIndex(x => x.id == id);
    this.originalList[originalValues].total_avertismente = value;
    if(foundIndex>-1) {
      this.generatedReport[foundIndex].total_avertismente = value;
      if(contraventie && this.generatedReport[foundIndex].total_sanctiuni) {

        if(this.generatedReport[foundIndex].total_avertismente || this.generatedReport[foundIndex].total_valoare) {
          this.generatedReport[foundIndex].missingValue = 0;
        } else {
          this.generatedReport[foundIndex].missingValue = 1;
        }
      }
    } else {

      let tmp: any = {};

      tmp.denumire = this.originalList[originalValues].name;
      tmp.infractiune_id = this.originalList[originalValues].id;
      if (this.masinaSelectata) {
        tmp.masina = this.masinaSelectata;
      }
      tmp.parent = this.parent;
      tmp.total_sanctiuni = 0;
      tmp.total_avertismente = value;
      tmp.total_valoare = 0;
      tmp.mentiuni = '';
      if(contraventie) {
        if( tmp.total_avertismente || tmp.total_valoare) {
          tmp.missingValue = 0;
        } else {
          tmp.missingValue = 1;
        }

      } else {
        tmp.missingValue = 0;
      }
      this.generatedReport.push(tmp);
    }
    this.tokenStorage.saveReport(this.generatedReport);
  }

  changeMentiuni(value, id) {
    if(this.generatedReport.length) {
      var foundIndex = this.generatedReport.findIndex(x => x.infractiune_id == id);
    } else {
      var foundIndex = -1;
    }
    var originalValues = this.originalList.findIndex(x => x.id == id);
    if(originalValues == -1) {
      var originalValues = this.originalList.findIndex(x => x.infractiune_id == id);
    }
    this.originalList[originalValues].mentiuni = value;
    if(foundIndex>-1) {
      this.generatedReport[foundIndex].mentiuni = value;
    } else {

      let tmp: any = {};

      tmp.denumire = this.originalList[originalValues].name;
      tmp.infractiune_id = this.originalList[originalValues].id;
      if (this.masinaSelectata) {
        tmp.masina = this.masinaSelectata;
      }
      tmp.parent = 0;
      tmp.total_sanctiuni = 0;
      tmp.total_avertismente = 0;
      tmp.total_valoare = 0;
      tmp.mentiuni = value;
      this.generatedReport.push(tmp);
    }
    this.tokenStorage.saveReport(this.generatedReport);
  }

  changeDate(element) {
    if(element.value) {
      this.selectedDate = this.formatDate(element.value);
    } else {
      this.selectedDate = element;
    }

    this.tokenStorage.saveData(this.selectedDate);
    this.checkStepOne();
  }

  checkStepOne() {
    if (this.selectedDate && this.selectedSchimb) {
      this.stepOneData = true;
    } else {
      this.stepOneData = false;
    }
  };

  changeSchimb(element) {
    if (element == "false") {
      this.selectedSchimb = false;
    } else {
      this.selectedSchimb = element;
    }
    this.tokenStorage.saveTura(this.selectedSchimb);
    this.checkStepOne();
  }

  changeAgent(element) {
    this.selectedAgent = element;
    this.tokenStorage.saveAgent(element);
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  final() {

    let data = {
      'raport': this.generatedReport,
      'tura': this.selectedSchimb,
      'data': this.selectedDate,
      'agent': this.selectedAgent
    };

    this.httpService.deleteCompletlyRaport(data).toPromise().then(
      deleted => {
        this.httpService.addRaport(data).toPromise().then(
          response => {
            this.alertService.success('Raportul a fost adaugat cu succes', this.options);
            window.scroll(0, 0);
            this.tokenStorage.saveReport(false);
            this.setStep(1);
            this.router.navigateByUrl('/home');
          }).catch(
            err => {
              this.alertService.error(err.error.message, this.options);
              window.scroll(0, 0);
                }
              );
      }).catch(
        err => {
          this.alertService.error(err.error.message, this.options);
          window.scroll(0, 0);
            }
          );

  }

  async updateList(search) {
    if (search && this.breadcrumb[1].id) {
      this.updatedList = this.originalList.filter(infr => infr.parent == this.breadcrumb[1].id);
      search = search.toLowerCase();
      this.updatedList = this.updatedList.filter(function(str) {
        const tmp = str.name.toLowerCase();
        if (tmp.includes(search)) {
          return true;
        }
        return false;
      });
      this.lis = this.updatedList;
    } else {
      this.lis = this.originalList.filter(lista => lista.parent == this.breadcrumb[1].id);
    }

  }


  async showSubCategories(newParent) {
    // this.setStep(2);
    this.breadcrumb = [];
    await this.setBreadcrumbs(newParent);

    this.breadcrumb.push({'name': 'ACTIVITATI', 'id': 0, 'parent': 0, 'infractiune_id': 0});
    this.parent = this.breadcrumb[0].infractiune_id;
    this.breadcrumb = this.breadcrumb.reverse();

    // this.parent = newParent;
    this.showOnlySelectedCategory(newParent)
  }

  async setBreadcrumbs(newParent) {
    if(newParent != 0) {
      let tmp = this.originalList.filter(lista => lista.id == newParent);
      this.breadcrumb.push(tmp[0]);
      if(tmp[0].parent) {
        await this.setBreadcrumbs(tmp[0].parent)
      }
    }
    return;
  }

  async remove(id) {
    var parentIndex = this.generatedReport.findIndex(x => x.infractiune_id == id);
    this.generatedReport.splice(parentIndex, 1);
    this.tokenStorage.saveReport(this.generatedReport)
    this.setStep(4);
  }

  async updateRemovedParentValue(item, valoare) {
    if (item[0]) {
      item = item[0];
    }
    if (valoare[0]) {
      valoare = valoare[0];
    }
    if (item.parent != 0 && item.parent != 229) {
      let parent = this.showReport.findIndex(x => x.id == item.parent);
      if (this.showReport[parent].total_valoare) {

        this.showReport[parent].total_valoare = parseInt(this.showReport[parent].total_valoare) - parseInt(valoare.total_valoare);
      }
      if(this.showReport[parent].total_sanctiuni)
        this.showReport[parent].total_sanctiuni = parseInt(this.showReport[parent].total_sanctiuni) - parseInt(valoare.total_sanctiuni);
      if(this.showReport[parent].total_avertismente)
        this.showReport[parent].total_avertismente = parseInt(this.showReport[parent].total_avertismente) - parseInt(valoare.total_avertismente);
      if (this.showReport[parent] != 0 && this.showReport[parent] != 229) {
        await this.updateRemovedParentValue(this.showReport[parent], valoare);
      }
    }

  }

  async updateParent(parentId, value){
    var parentIndex = this.originalList.findIndex(x => x.infractiune_id == parentId);
    if ((this.originalList[parentIndex].parent !== 0 || this.originalList[parentIndex].id == 54 || this.originalList[parentIndex].id == 263 || this.originalList[parentIndex].id == 264) && this.originalList[parentIndex].parent !== 229 && this.originalList[parentIndex].id !== 322) {
      if (!this.originalList[parentIndex].total_sanctiuni) {
        this.originalList[parentIndex].total_sanctiuni = 0;
      }
      if (!this.originalList[parentIndex].total_valoare) {
        this.originalList[parentIndex].total_valoare = 0;
      }
      if (!this.originalList[parentIndex].total_avertismente) {
        this.originalList[parentIndex].total_avertismente = 0;
      }

      this.originalList[parentIndex].hasValue = true;
      this.originalList[parentIndex].total_sanctiuni = parseInt(this.originalList[parentIndex].total_sanctiuni) + parseInt(value.total_sanctiuni);
      this.originalList[parentIndex].total_valoare = parseInt(this.originalList[parentIndex].total_valoare) + parseInt(value.total_valoare);
      this.originalList[parentIndex].total_avertismente = parseInt(this.originalList[parentIndex].total_avertismente) + parseInt(value.total_avertismente);

      if(this.originalList[parentIndex].parent !== 0) {
        await this.updateParent(this.originalList[parentIndex].parent, value);
      }
    } else {
      if (this.originalList[parentIndex].parent == 229) {
        var newParent = this.originalList.findIndex(x => x.id == 229);
        this.originalList[newParent].hasValue = true;
        this.originalList[parentIndex].hasValue = true;
      }
      if (this.originalList[parentIndex].parent == 0 && this.originalList[parentIndex].id != 322) {
        this.originalList[parentIndex].hasValue = true;
      }
    }
  }

  async updateOriginalList(data) {
    this.hasAllData = true;
    for (const [i, v] of data.entries()) {
      if (v.infractiune_id !== 322) {
        let infractiuneIndex = this.originalList.findIndex(lista => lista.id == v.infractiune_id);
        this.originalList[infractiuneIndex].hasValue = true;
        this.originalList[infractiuneIndex].infractiune_id = v.infractiune_id;
        if(v.missingValue) {
          this.hasAllData = false;
        }
        this.originalList[infractiuneIndex].missingValue = v.missingValue;
        if(this.originalList[infractiuneIndex].total_avertismente) {
          this.originalList[infractiuneIndex].total_avertismente = parseInt(this.originalList[infractiuneIndex].total_avertismente) + parseInt(v.total_avertismente);
        } else {
          if(v.total_avertismente) {
            this.originalList[infractiuneIndex].total_avertismente = parseInt(v.total_avertismente);
          }
        }
        if(this.originalList[infractiuneIndex].total_sanctiuni) {
          this.originalList[infractiuneIndex].total_sanctiuni = parseInt(this.originalList[infractiuneIndex].total_sanctiuni) + parseInt(v.total_sanctiuni);
        } else {
          if(v.total_sanctiuni) {
            this.originalList[infractiuneIndex].total_sanctiuni = parseInt(v.total_sanctiuni);
          }
        }
        if(this.originalList[infractiuneIndex].total_valoare) {
          this.originalList[infractiuneIndex].total_valoare = parseInt(this.originalList[infractiuneIndex].total_valoare) + parseInt(v.total_valoare);
        } else {
          if(v.total_valoare) {
            this.originalList[infractiuneIndex].total_valoare = parseInt(v.total_valoare);
          }
        }
        await this.updateParent(this.originalList[infractiuneIndex].parent, v);

      } else {
         this.mentiuni.push(v);
      }
    }
  }

  async saveInReport(data) {
    this.mentiuniRaport = true;
    this.mentiuni = [];
    await this.updateOriginalList(data);
    this.finalRaport = this.originalList.filter(lista => lista.parent == 0);
    for (const [key, value] of this.finalRaport.entries()) {

        let child = await this.getChild(value)
        this.finalRaport[key].child = child;
    }
      //now we delete the ones that have no values
      this.showReport = [];
      for (const [key, value] of this.finalRaport.entries()) {
        if (value.child.length) {
          if (value.id == 54) {
            if (value.total_sanctiuni) {
              if(value.total_avertismente || value.total_valoare) {
                this.totalContraventii = {
                  denumire : 'Total Contraventii',
                  total_avertismente: parseInt(value.total_avertismente),
                  total_sanctiuni : parseInt(value.total_sanctiuni),
                  total_valoare : parseInt(value.total_valoare),
                  hasChild : 1
                }
              } else {
                this.totalContraventii = {
                  denumire : 'Total Contraventii',
                  total_avertismente: parseInt(value.total_avertismente),
                  total_sanctiuni : parseInt(value.total_sanctiuni),
                  total_valoare : parseInt(value.total_valoare),
                  hasChild : 1
                }
              }
              this.showReport.push(this.totalContraventii);
              this.contraventiiIndex = this.showReport.length -1;
            }

          }
          if (value.id == 263) {
            if (value.total_sanctiuni) {
              if(this.contraventiiIndex > -1) {
                this.showReport[this.contraventiiIndex].total_avertismente = parseInt(this.showReport[this.contraventiiIndex].total_avertismente) + parseInt(value.total_avertismente);
                this.showReport[this.contraventiiIndex].total_sanctiuni = parseInt(this.showReport[this.contraventiiIndex].total_sanctiuni) + parseInt(value.total_sanctiuni);
                this.showReport[this.contraventiiIndex].total_valoare = parseInt(this.showReport[this.contraventiiIndex].total_valoare) + parseInt(value.total_valoare);
              } else {
                if(value.total_avertismente || value.total_valoare) {
                  this.totalContraventii = {
                    denumire : 'Total Contraventii',
                    total_avertismente: parseInt(value.total_avertismente),
                    total_sanctiuni : parseInt(value.total_sanctiuni),
                    total_valoare : parseInt(value.total_valoare),
                    hasChild : 1
                  }
                } else {
                  this.totalContraventii = {
                    denumire : 'Total Contraventii',
                    total_avertismente: parseInt(value.total_avertismente),
                    total_sanctiuni : parseInt(value.total_sanctiuni),
                    total_valoare : parseInt(value.total_valoare),
                    hasChild : 1
                  }
                }

                this.showReport.push(this.totalContraventii);
                this.contraventiiIndex = this.showReport.length -1;
              }
            }
          }
          await this.addToReport(value, 1);
        }
        if (value.id == 322 && value.mentiuni) {
          await this.addToReport(value, 1);
        }
      }
  }

  async addToReport(childValue, level) {
    if (childValue.child) {
      if (childValue.parent == 0) {
        if (!childValue.hasValue) {
          return;
        }
      } else {
          if (!childValue.hasValue) {
            return;
          }
        }
        childValue.level = level;
      if (childValue.name.charAt(0) != '-') {
        let lines = ''
        for (let i = 0; i < level; i++) {
          lines += '-';
        }

        childValue.name = lines +childValue.name;
      }

      this.showReport.push(childValue);

      level++;
      for (const [k, v] of childValue.child.entries()) {
        if (v.child && v.child.length) {
          await this.addToReport(v, level);
        } else {
          if (v.total_sanctiuni) {
            v.level = level;
            if (v.name.charAt(0) != '-') {
              let lines = ''
              for (let i = 0; i < level; i++) {
                lines += '-';
              }

              v.name = lines + v.name;
            }
            this.showReport.push(v);
          }
        }
      }
    }
  }

  async getChild(child) {
    let tmpChild = this.originalList.filter(lista => (lista.parent == child.id && lista.hasChild == false && lista.total_sanctiuni));
    let tmpLowerChildren = this.originalList.filter(lista => (lista.parent == child.id && lista.hasChild == true));
    for (const [key, value] of tmpLowerChildren.entries()) {
      let child = await this.getChild(value);
      if (child.length) {
        tmpLowerChildren[key].child = child;
      }
    }
    //now we remove the childless
    let report = tmpChild.concat(tmpLowerChildren);
    return report;
  }

  adaugarePermiseRetinute(infractiuneId, total) {
    let permis = this.permiseAutomatRetinute.filter(list => list.contraventieId == infractiuneId);

    if(permis.length) {
      this.changeSanctiuni(total, permis[0].permis);
    }

  }
}
