import { Component, destroyPlatform, ElementRef, OnInit } from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import { first } from 'rxjs/operators';
import {AlertService} from '../_alert';
import {AuthService} from '../_services/auth.service';

@Component({
  selector: 'app-edit-infractiune',
  templateUrl: './edit-infractiune.component.html',
  styleUrls: ['./edit-infractiune.component.css']
})
export class EditInfractiuneComponent implements OnInit {

  editInfractiuneForm = new FormGroup({
    name: new FormControl(''),
    structura: new FormControl(''),
  });

  id: string;
  display = false;

  options = {
        autoClose: true,
        keepAfterRouteChange: true
  };

  locatii = ['Toate Structurile', 'Serviciul Rutier Bihor', 'Biroul Drumuri Nationale si Europene', 'Biroul Rutier Oradea', 'Biroul Rutier Beius', 'Biroul Rutier Marghita', 'Biroul Rutier Salonta', 'Compatimentul Rutier Alesd', 'Politie Statiune Baile Felix'];

  constructor(private httpService: ApiService, private router: Router, private elementRef:ElementRef, private route: ActivatedRoute, public alertService: AlertService, public authService: AuthService) {

  }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.params['id'];
    const infractiune = await this.httpService.getInfractiuneById(this.id).toPromise().then();
    this.display = true;
    this.editInfractiuneForm.patchValue(infractiune);
  }

  onSubmit() {
       this.httpService.updateInfractiune(this.editInfractiuneForm.value, this.id).toPromise().then(
         response => {
           this.alertService.success('Infractiunea a fost actualizata cu success', this.options);
           window.scroll(0, 0);
        this.router.navigateByUrl('/infractiune');
      }).catch(
        err => {
          this.alertService.error(err.error.message, this.options);
          window.scroll(0, 0);
            }
          );
  }

}
