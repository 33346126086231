<!-- Begin Page Content -->
<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800">Adaugare Infractiune/Contraventie</h1>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-body">
      <form [formGroup]="addInfractiuneForm" (ngSubmit)="onSubmit()" class="user" id='addInfractiuneForm'>
        <div class="form-group">
          <input type="text" class="form-control form-control-user" id="name" formControlName="name" placeholder="Denumire"
            required>
        </div>

        <div class="form-group row">
          <div class="col-sm-3 mb-3 mb-sm-0">
            <div class="form-group">
              <label for="inputEmail4">Structura</label>
              <select formControlName="structura" class="form-control">
                <option *ngFor='let loc of locatii' [value]="loc">
                  {{loc}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary btn-sm float-right mr-1">
          Adaugare</button>

        <a routerLink="/infractiune" class="btn btn-sm float-right mr-1">Inapoi</a>

      </form>
    </div>
  </div>
</div>
